:root {
  --whiteBg: #ffff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.2s ease;
  scroll-behavior: smooth !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

::-webkit-scrollbar {
  width: 0;
}

.light-text {
  color: var(--lightText);
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.lnh-16 {
  line-height: 16px !important;
}

.lnh-18 {
  line-height: 18px !important;
}

.lnh-22 {
  line-height: 22px !important;
}

.lnh-26 {
  line-height: 26px !important;
}

.lnh-36 {
  line-height: 36px !important;
}

.lnh-45 {
  line-height: 45px !important;
}

.fs-12 {
  font-size: 0.8rem !important;
  line-height: 1.3;
}

.fs-13 {
  font-size: 0.85rem !important;
  line-height: 1.2;
}

.fs-15 {
  font-size: 1rem !important;
  line-height: 1.3;
}

.fs-18 {
  font-size: 1.2rem !important;
  line-height: 1.3;
}

.black-text {
  color: var(--blackText);
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.lnh-16 {
  line-height: 16px !important;
}

.lnh-18 {
  line-height: 18px !important;
}

.lnh-22 {
  line-height: 22px !important;
}

.lnh-26 {
  line-height: 26px !important;
}

.lnh-36 {
  line-height: 36px !important;
}

.lnh-45 {
  line-height: 45px !important;
}

.fs-12 {
  font-size: 0.8rem !important;
  line-height: 1.3;
}

.fs-13 {
  font-size: 0.85rem !important;
  line-height: 1.2;
}

.fs-15 {
  font-size: 1rem !important;
  line-height: 1.3;
}

.fs-18 {
  font-size: 1.2rem !important;
  line-height: 1.3;
}

/*------------------------------- Text styles  ------------------------------- */

.error-text {
  color: red;
}

.success-text {
  color: #09bd06 !important;
}

.pink-text {
  color: rgb(238, 22, 58) !important;
}

.success-text {
  color: #23be23;
}

.Heading-Blue {
  color: rgb(98, 124, 170);
  font-weight: 600;
  font-size: 1.3rem;
}

.Heading-Bold {
  font-weight: 600;
}

/* ------------------------------- Main Section ------------------------------- */

.main-section {
  padding: 20px;
}

/* ------------------------------- Sidebar ------------------------------- */
.sidebar-expend {
  width: 3.5em !important;
}

/*  ---------------------------------- Loader  ----------------------------------*/
.loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------- page-title-with-action ------------------------------- */

.page-title-with-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 10px;
}

.withdrawal-request .page-title-with-action {
  width: 75%;
}

.page-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn {
  background-color: #293954fd;
}

.outlinedBtn {
  border: 1px solid #1e293b4b;
  color: #1e293bc5;
}

.containedBtn {
  background-color: #293954fd;
  color: white;
}

.position-relative {
  position: relative !important;
}

.refreshBtn {
  position: absolute;
  top: -60px;
  right: 10px;
}

.user-refresh {
  right: 230px;
}

.all-market-refresh {
  right: 130px;
}

.all-payment-gateway-refresh {
  right: 220px;
}

.debit-refresh {
  right: 80px;
}

.admin-user-refresh {
  right: 164px;
}

.graph-data {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.outlinedBtn:hover {
  border: 1px solid #1e293bc4;
}

.btn:hover {
  background-color: #132747;
}

.white-box-graph {
  background-color: var(--whiteBg);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
}

/* ---------------------------- User icons && Table -----------–––––------------------*/

.user-icons {
  white-space: nowrap !important;
}

.user-icons .icon1 {
  width: 25px;
  filter: invert(58%) sepia(48%) saturate(5209%) hue-rotate(328deg) brightness(102%) contrast(101%);
}

.user-icons .user-wallet-icon1 {
  width: 35px;
  filter: invert(58%) sepia(48%) saturate(5209%) hue-rotate(328deg) brightness(102%) contrast(101%);
}

.user-icons .icon2 {
  width: 25px;
  filter: invert(87%) sepia(23%) saturate(5495%) hue-rotate(329deg) brightness(101%) contrast(98%);
}

.user-icons .icon3 {
  width: 25px;
  filter: invert(20%) sepia(13%) saturate(2818%) hue-rotate(180deg) brightness(94%) contrast(90%);
}

.user-icons .icon3Disabled {
  width: 25px;
  filter: grayscale(100%);
  opacity: 30%;
}

.user-icons .icon4 {
  width: 25px;
  filter: invert(87%) sepia(9%) saturate(2887%) hue-rotate(94deg) brightness(86%) contrast(87%);
}

.table-container thead th {
  border-bottom: none;
}

.table-row:hover {
  background-color: #f8f9fb;
}

.ChildTable {
  padding: 0px 30px 30px;
}

.ChildTable .ChildTableHeading {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #838893;
  text-align: start;
}

.ChildTable thead {
  background-color: #d0dbe7;
}

.ChildTable tbody {
  background-color: #f8f9fb;
}

.noRecordFound {
  font-size: 1.3rem;
  text-align: center !important;
  height: 200px;
  color: rgb(98, 124, 170);
  font-weight: 700;
}

.noWinningUsers {
  font-size: 1.3rem;
  text-align: center !important;
  height: 200px;
  color: #ee502d;
  font-weight: 700;
}

.UserPassbook-Table thead th {
  padding: 17px 7px;
}

.RolePermission-Table tbody tr td {
  padding-left: 18px !important;
}

.table-container tbody tr td,
.table-container tbody tr th {
  border-bottom: 1px solid rgb(209 204 204) !important;
}

.table-container tbody tr td .chip span {
  padding: 9px 12px !important;
}

/*---------------------------- Mui Clock && Date range picker ----------------------------*/
.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockPointer-thumb,
.css-xlpkg6-MuiClockPointer-thumb {
  background-color: #2b3a55 !important;
}

.css-xlpkg6-MuiClockPointer-thumb {
  border: 16px solid #2b3a55 !important;
}

.css-19zry94-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
.css-1077mjw-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background-color: #2b3a55 !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  color: black !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span:after,
.rdrDayToday .rdrDayNumber span:after {
  background: black !important;
}

/*---------------------------- TableFooter ----------------------------*/

.TableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 45px;
  padding: 0px 5px;
}

.TableFooter .rowsPerPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.TableFooter .rowsPerPage .label {
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;
  color: #a3a3a3;
}

.TableFooter .rowsPerPage .border-less-control .MuiSelect-outlined {
  padding: 4px 28px 4px 10px;
  font-size: 15px;
  line-height: 26px;
  color: #3e3e3e;
}

.TableFooter .rowsPerPage .border-less-control .MuiSelect-icon {
  border-color: #3e3e3e;
}

.TableFooter .rowsPerPage .border-less-control .MuiOutlinedInput-notchedOutline {
  display: none;
}

.Notifications-Table td:nth-child(1),
.Notifications-Table th:nth-child(1) {
  text-align: start;

  @media (max-width: 850px) {
    .TableFooter .rowsPerPage {
      margin-bottom: 0;
    }

    .TableFooter {
      flex-direction: column;
      gap: 13px;
    }
  }
}

.NoBorderTable .MuiTable-root {
  border: 0;
  max-width: 650px;
  min-width: 460px;
}

.NoBorderTable .MuiTableHead-root .MuiTableCell-head {
  border: 0;
  font-size: 15px;
  line-height: 1.2;
  color: #a3a3a3;
  padding: 0 10px 8px 10px;
}

.NoBorderTable .MuiTableBody-root .MuiTableCell-body {
  padding: 12px 10px;
  border: 0;
}

.NoBorderTable .MuiTableBody-root .MuiTableCell-body:first-child {
  padding-left: 16px;
}

.Border-Table-container table.Border-Table,
.Border-Table-container table.Border-Table th,
.Border-Table-container table.Border-Table td {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
}

.Border-Table-container table.Border-Table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
  width: 25%;
}

.MarketForm-Table tr td {
  padding: 11px;
}

/* ---------------------------------- popover ----------------------------------*/
.UserDropDown {
  width: 960px;
  min-height: 300px;
}

.UserDropDown::before {
  width: 100vw;
  height: 100vh;
  content: "";
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
}

.UserDropDown .usermenu {
  margin: 5px 0;
  padding: 20px;
}

.rdrDefinedRangesWrapper {
  width: 25%;
}

.rdrCalendarWrapper {
  width: 74%;
}

.UserDropDown .usermenu-button {
  width: 96%;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 20px 0;
}

/* ---------------------------------- Input Box  ----------------------------------*/

.css-1q62z36-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 1.1025em;
}

.css-jdqif4-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1e293b !important;
  border: 1.6px solid #1e293b !important;
}

.phone-input-dialog-main .MuiPaper-root {
  overflow: visible;
}

.phone-input-dialog {
  overflow: visible;
}

/* ---------------------------------- Cuatom Tabbing  ----------------------------------*/

.tabs-heading {
  padding: 4px;
  border-radius: 12px;
  border: 2px solid #1e293b4b;
  width: fit-content;
}

.passbook-data-form {
  width: 360px;
  display: flex;
  gap: 10px;
  margin-right: 10px;
}

/* ---------------------------------- File Upload  ----------------------------------*/

.input-field-upload {
  text-align: center;
  border: 1px solid #b1b1b1;
  border-radius: 10px;
  padding: 20px 0px;
  overflow: hidden;
}

.input-field-upload:hover {
  border-color: #1E293B;
}

.upload-lable {
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.file-upload-icon {
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #a3a3a3;
}

.file-upload-icon svg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  color: #1E293B;
}

.form-upload-img {
  width: 150px;
  height: 150px;
  overflow: hidden !important;
  margin: 0 auto;
}

.form-upload-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-upload-pdf {
  width: 250px;
  height: 300px;
  overflow: hidden !important;
}

.react-pdf__Page {
  height: 400px !important;
}

.react-pdf__Page__canvas {
  width: 250px !important;
  height: 300px !important;
}

.error-red-border {
  border-color: #d32f2f !important;
}

/* ---------------------------------- Media Queary  ----------------------------------*/

@media only screen and (max-width: 1050px) {
  .UserDropDown {
    width: 850px;
  }

  .rdrDefinedRangesWrapper {
    width: 140px !important;
  }

  .rdrCalendarWrapper {
    width: 620px !important;
  }
}

@media only screen and (max-width: 899px) {
  .UserDropDown {
    width: 800px;
  }

  .rdrCalendarWrapper {
    width: 570px !important;
  }
}

@media only screen and (max-width: 850px) {
  .UserDropDown {
    width: 750px;
  }

  .rdrCalendarWrapper {
    width: 530px !important;
  }
}

@media only screen and (max-width: 800px) {
  .UserDropDown {
    width: 700px;
  }

  .rdrCalendarWrapper {
    width: 480px !important;
  }
}

@media only screen and (max-width: 750px) {
  .UserDropDown {
    width: 650px;
  }

  .rdrDefinedRangesWrapper {
    width: 125px !important;
  }

  .rdrCalendarWrapper {
    width: 450px !important;
  }
}

@media only screen and (max-width: 660px) {
  .UserDropDown {
    width: 550px;
  }

  .rdrDefinedRangesWrapper {
    width: 110px !important;
  }

  .rdrCalendarWrapper {
    width: 360px !important;
  }
}